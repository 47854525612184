<template>
    <v-card :title="data.nombre" outlined flat rounded :to="{ name: 'dashboard_cap_item', params: { id: data.id } }">
        <v-card flat tile dark color="#777" class="py-0">
        <v-list-item>
            <v-list-item-icon class="mr-2">
                <v-icon color="secondary">folder</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ma-0 subtitle-1 font-weight-medium">
                {{ data.nombre }}
            </v-list-item-title>
            <v-list-item-avatar class="ma-0">
                <v-icon size="40">chevron_right</v-icon>
            </v-list-item-avatar>
        </v-list-item>
        </v-card>
        <v-divider />
        <v-card-text class="pa-2">
            <span class="d-block subtitle-1">{{ data.empresa.nombre }}, {{ data.gerencia.nombre }}</span>
            <span class="d-block body text--primary">{{ data.descripcion }}</span>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    props: {
        data: { type: Object, default: null },
        to: { type: Object, default: null },
        readonly: { type: Boolean, default: false },
        edit: { type: Boolean, default: false },
        admin: { type: Boolean, default: false }
    },
    computed: {
        autor() {
            if(!this.data || !this.data.autor) return "Autor Desconocido";
            return this.data.autor;
        }
    }
}
</script>