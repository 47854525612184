<template>
    <v-skeleton-loader :loading="!!loading" type="list-item, list-item, list-item, list-item">
        <v-data-table :items="data" :headers="header" :items-per-page="5" :hide-default-header="hideHeader" group-by="grupo.nombre" item-key="id" sort-by="fecha_finalizado">
            <template v-slot:group.header="{ group, toggle, isOpen, headers }">
                <td :colspan="headers.length" class="pa-0" @click="toggle">
                    <v-row no-gutters justify="center">
                        <v-col class="flex-grow-1 flex-shrink-0 fill-height pa-2">
                            <span :title="group.nombre">
                                <v-icon left>group</v-icon>{{ group | see_more(140) }}
                            </span>
                            <span class="caption d-block" v-if="finalizado">
                                {{ get_fecha(group) }}
                            </span>
                        </v-col>
                        <v-col class="flex-grow-0 flex-shrink-1 pa-2">
                            <v-icon v-if="!isOpen">expand_more</v-icon>
                            <v-icon v-else>expand_less</v-icon>
                        </v-col>
                    </v-row>
                </td>
            </template>
            <template v-slot:item.nombre="{ item }">
                <v-list dense class="pa-0" color="transparent">
                    <v-list-item class="pa-0">
                        <v-list-item-icon class="my-auto mr-2">
                            <v-icon :color="caducado ? 'red' : (finalizado ? 'green' : 'gray')">folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2">{{ item.nombre }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ !item.empresa ? "Empresa desconocida" : item.empresa.nombre }}, {{ !item.gerencia ? "Gerencia desconocida" : item.gerencia.nombre }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <template v-slot:item.fecha_finalizado="{ item }">
                <v-icon left color="green">check_circle</v-icon>{{ item.fecha_finalizado | moment("L HH:mm") }}
            </template>
            <template v-slot:item.fecha_inicio="{ item }">
                {{ item.grupo.fecha_inicio | moment("L") }} al {{ item.grupo.fecha_termino | moment("L") }}
            </template>
        </v-data-table>
    </v-skeleton-loader>
</template>

<script>
import moment from 'moment';

export default {
    props: ['data', 'loading', 'finalizado', 'caducado', 'hide-header'],
    computed: {
        header() {
            return this.finalizado ? this.headers_finish : this.headers_general;
        }
    },
    methods: {
        get_fecha(group) {
            var result = this.data.filter(x => x.grupo.nombre == group);
            var text = "";

            if(!!result && result.length > 0) {
                var grupo = result[0].grupo;
                text += moment(grupo.fecha_inicio).format("L");
                text += " al ";
                text += moment(grupo.fecha_termino).format("L");
            }

            return text;
        }
    },
    data: () => ({
        headers_finish: [
            { value: "nombre", text: "Nombre" },
            { value: "fecha_finalizado", text: "Finalizado" }
        ],
        headers_general: [
            { value: "nombre", text: "Nombre" },
            { value: "fecha_inicio", text: "Disponible" }
        ]
    })
}
</script>