<template>
<div>
    <v-row v-if="$parent.$parent.isin(['supers'])">
        <v-col cols="12">
            <v-btn class="subtitle-2 ma-1" dark large depressed color="secondary" :to="{ name: 'dashboard_cap_create' }">
                <v-icon left>add</v-icon>
                Crear nuevo
            </v-btn>    
        </v-col>
        <v-col cols="12">
            <DashCard title-icon="list" title="Todos los cursos existentes">
                <v-skeleton-loader :loading="!!loading" type="article, article, article">
                        <v-data-iterator :items="index" hide-default-footer :items-per-page.sync="pagination.item_per" :page="pagination.page">
                            <template v-slot:header>
                                <v-pagination v-model="pagination.page" :length="pagination_length" />
                            </template>
                            <template v-slot:item="{ item }">
                                <div class="pa-2 d-inline-block" style="width: 50%">
                                    <CursoItem :data="item" :edit="$parent.$parent.isin(['supers'])" :admin="$parent.$parent.isin(['supers'])" />
                                </div>
                            </template>
                            <template v-slot:no-data>
                                <EmptyCard icon="inbox" text="No hay nuevas capacitaciones" />
                            </template>
                            <template v-slot:footer>
                                <v-pagination v-model="pagination.page" :length="pagination_length" />
                            </template>
                        </v-data-iterator>
                </v-skeleton-loader>
            </DashCard>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12">
            <DashCard title-icon="star" title="Disponibles" body-class="pa-0">
                <div class="pa-4">
                    <v-select hide-details flat solo outlined clearable dense v-model="current_grupo" :items="index.activas" item-text="nombre" placeholder="Navegar por grupos de acceso" return-object prepend-inner-icon="group">
                        <template v-slot:prepend>    
                            <v-btn large depressed dark color="secondary" class="mt-n2 subtitle-2" @click="grupo_back">
                                <v-icon large>chevron_left</v-icon>
                            </v-btn>
                        </template>
                    </v-select>
                </div>
                <v-divider />
                <v-skeleton-loader :loading="!!loading" type="image, article" class="pa-4">
                    <div v-if="current_grupo">
                        <div class="mb-4">
                        <v-card outlined tile flat dark color="primary">
                            <v-card-title class="pa-2">
                                <v-icon left>group</v-icon>{{ current_grupo.nombre }}
                            </v-card-title>
                        </v-card>
                        <v-card outlined tile flat>
                            <v-card-text class="pa-2">
                                <v-chip dark label color="indigo" class="my-1 mr-1">
                                    <v-icon left>account_circle</v-icon>{{ current_grupo.evaluador_name }}
                                </v-chip>
                                <span class="d-block">
                                    Cursos disponibles desde <b>{{ current_grupo.fecha_inicio | moment("LL") }}</b> hasta <b>{{ current_grupo.fecha_termino | moment("LL") }}</b>
                                </span>
                                <v-chip dark label color="red" v-if="get_days(current_grupo) < 5">
                                    <span class="font-weight-bold">¡Quedan {{ get_days(current_grupo) }} día(s)!</span>
                                </v-chip>
                            </v-card-text>
                            <v-card-text v-if="!!current_grupo.ref_incidente">
                                <v-label>La realización de estos cursos están asociados al siguiente <b>incidente ambiental</b>:</v-label>
                                <IncidenteCard v-model="current_grupo.ref_incidente" />
                            </v-card-text>
                        </v-card>
                        </div>
                        <v-row no-gutters class="ma-n2">
                            <v-col cols="12" md="6" class="pa-2" v-for="(curso, i) in current_grupo.cursos" :key="i">
                                <CursoListItem :data="curso" :key="i" />
                            </v-col>
                        </v-row>
                    </div>
                    <v-row class="ma-n2">
                        <v-col class="pa-4" v-if="index.activas?.length < 1">
                            <EmptyCard icon="inbox" text="No hay nuevas capacitaciones asignadas para usted" />
                        </v-col>
                        <v-col class="pa-0" v-else-if="index.activas?.length > 0 && !current_grupo">
                            <v-alert outlined color="primary" dense type="info" class="mx-2 my-1" dismissible>
                                Presione en algún grupo de acceso para acceder a los cursos asignados.
                            </v-alert>
                            <v-row class="ma-0">
                                <v-col cols="12" md="6" v-for="(grupo, i) in index.activas" :key="i">
                                    <GrupoListItem :data="grupo" :key="i" @open="g => current_grupo = g" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-skeleton-loader>
            </DashCard>
        </v-col>
        <v-col cols="12" md="6">
            <DashCard title-icon="check_circle" title="Finalizadas" body-class="pa-0">
                <div class="pa-4">
                    <v-label>
                        Cursos en los que se <b>han completado la totalidad de sus evaluaciones</b> dentro del tiempo en los que estuvieron disponibles.
                    </v-label>
                </div>
                <v-divider />
                <CursoLogTable :finalizado="true" :loading="loading" :data="index.finalizadas" :hide-header="true" />
            </DashCard>
        </v-col>
        <v-col cols="12" md="6">
            <DashCard title-icon="cancel" title="Caducadas" body-class="pa-0">
                <div class="pa-4">
                    <v-label>
                        Cursos en los que <b>no se rindieron todas sus evaluaciones</b> dentro del tiempo en los que estuvieron disponibles.
                    </v-label>
                </div>
                <v-divider />
                <CursoLogTable :caducado="true" :loading="loading" :data="index.caducadas" :hide-header="true" />
            </DashCard>
        </v-col>
    </v-row>
</div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import EmptyCard from '@/shared/components/EmptyCard'
import IncidenteCard from '@/educap/components/IncidenteCard'
import CursoItem from '@/educap/components/CursoItem'
import GrupoListItem from '@/educap/components/GrupoListItem'
import CursoListItem from '@/educap/components/CursoListItem'
import CursoLogTable from '@/educap/components/CursoLogTable'
import { axiosHelper } from '@/helpers'
import moment from 'moment'

export default {
    components: {
        DashCard,
        EmptyCard,
        IncidenteCard,
        CursoItem,
        GrupoListItem,
        CursoListItem,
        CursoLogTable
    },
    computed: {
        pagination_length() {
            if(!this.index) return 1;
            return this.index?.length > 0 ? Math.round(this.index?.length / this.pagination.item_per) : 1;
        }
    },
    data: () => ({
        index: {},
        pagination: {
            page: 1,
            item_per: 16,
            total: 1
        },
        current_grupo: null,
        error: false,
        loading: false
    }),
    methods: {
        grupo_back() {
            if(!this.current_grupo) return;
            this.current_grupo = null;
        },
        get_days(grupo) {
            var termino = moment(grupo.fecha_termino);
            var now = moment();
            return termino.diff(now, 'd') + 1;
        }
    },
    mounted() {
        this.loading = true;
        this.$parent.$parent.set_title("Cursos", "folder");
        
        axiosHelper.get('edu/cursos')
                .then(response => {
                    this.index = response.data;
                    this.loading = false;
                })
                .catch(response => {
                    this.error = true;
                    this.index = [];
                    this.loading = false;
                });
    }
}
</script>