<template>
    <v-card outlined flat @click="$emit('open', data)">
        <v-list-item three-line>
            <v-list-item-content>
            <v-list-item-title class="font-weight-bold"><v-icon left>group</v-icon>{{ data.nombre }}</v-list-item-title>
            <v-list-item-subtitle>
                {{ data.cursos.length }} curso(s) disponible(s)
            </v-list-item-subtitle>
            <v-list-item-subtitle>
                Disponibles desde <b>{{ data.fecha_inicio | moment("L") }}</b> hasta <b>{{ data.fecha_termino | moment("L") }}</b>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="!!data.ref_incidente">
                Asociado al incidente <b>Nº {{ data.ref_incidente.cod_incidente }}: "{{ data.ref_incidente.titulo }}"</b>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
                <v-chip color="indigo" dark><v-icon left>account_circle</v-icon>{{ data.evaluador_name }}</v-chip>
            </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar tile size="60">
                <v-icon color="primary" size="60">chevron_right</v-icon>
            </v-list-item-avatar>
        </v-list-item>
        <v-divider />
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Cursos asociados</th>
                        <th>Empresa</th>
                        <th>Gerencia</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="data.cursos.length > 0">
                        <tr v-for="(curso, i) in data.cursos" :key="i">
                            <td><v-icon color="secondary" left>folder</v-icon>{{ curso.nombre | see_more(40) }}</td>
                            <td>{{ curso.empresa.nombre }}</td>
                            <td>{{ curso.gerencia.nombre }}</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="3">
                                No se han asignado cursos.
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
export default {
    props: ['data']
}
</script>